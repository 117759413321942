function ContentService ( $q, $sce, $location, $routeParams, MetaService, StatusCodeService, AppConfigService, RequestService, CategoriesService )
{
    "ngInject";

    var page = undefined;

    var menu = {
        items: []
    };

    var loadPage = function ()
    {
        var deferred = $q.defer();
        var pageUrlName = $routeParams.name;

        var data = {
            request: 'getContentPage',
            page: pageUrlName
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response )
            {
                page = response.data;
                page.urlName = pageUrlName;
                StatusCodeService.setStatusCode('200');

                page.content = $sce.trustAsHtml(page.content)
                var description = "";
                switch(true) {
                    case page.urlName === 'dostavka':
                        description = "Условия бесплатной доставки цветов в городе " + AppConfigService.options.config.city + " от салона " + AppConfigService.options.config.shop_name;
                        break;
                    case page.urlName === 'oplata':
                        description = "Оплати цветы онлайн прямо на сайте! Салон цветов " + AppConfigService.options.config.shop_name + " принимает оплату PAYPAL, картами Visa и MasterCard.";
                        break;
                    case page.urlName === 'contacts':
                        description = "Адреса и телефоны салонов цветов " + AppConfigService.options.config.shop_name + " в городе " + AppConfigService.options.config.city;
                        break;
                    case page.urlName === 'rozigrish':
                        description = "Розыгрыш призов от салона цветов " + AppConfigService.options.config.shop_name + " в городе " + AppConfigService.options.config.city;
                        break;
                    case page.urlName === 'garantiya-vypolneniya-zakazov':
                        description = "Гарантии выполнения заказов на доставку цветов от салона цветов " + AppConfigService.options.config.shop_name + " в городе " + AppConfigService.options.config.city;
                        break;

                }

                MetaService.setMetaTags({
                    title: page.name + ", Доставка цветов " + AppConfigService.options.config.city,
                    description: description,
                    keywords: AppConfigService.options.config.system_keywords,
                    img: AppConfigService.options.config.logo
                });
/* off gtag after 24.02.22
                if (pageUrlName == 'contacts') {
                    gtag('event', 'conversion', {'send_to': 'AW-1001962874/pGKOCKeswZQBEPr64t0D'});
                }
*/
                deferred.resolve( page );
            })
            .error( function ( data, status, headers, config )
            {
                deferred.reject( data.data );
                $location.url( "/404" );
            });

        page = deferred.promise;
        CategoriesService.states.activeItem = undefined;
        return $q.when( page );
    };

    var getMapCoordinates = function() {
        return AppConfigService.options.config.selfdelivery_points.map(function (item) {
            return {
                id: item.id,
                geometry: {
                    type: "Point",
                    coordinates: item.coords
                },
                properties: {
                    balloonContentHeader: item.name
                }
            };
        });
    };

    var getMenu = function () {
        var data = {
            request: 'getMenu'
        };

        RequestService.processRequest("/api/handler.php", data).success( function ( response ){menu.items = response.data;});
    };

    return {
        menu: menu,
        loadPage: loadPage,
        getMapCoordinates: getMapCoordinates,
        getMenu: getMenu
    }

}

module.exports = ContentService;