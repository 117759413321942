function AppController ( $window, $scope, $location, $rootScope, $metrika, $routeParams, AppService, AppConfigService, SearchService, CategoriesService, ContentService, BannersService, CartService, AdditionalGoodsService )
{
    'ngInject';
    var vm = this;

    var path = $location.path();

    vm.appConfig = AppConfigService;
    vm.categoriesList = [];
    vm.categories = CategoriesService;
    vm.content = ContentService;
    vm.addGoods = AdditionalGoodsService;
    vm.cartService = CartService;
    vm.search = SearchService;

    $rootScope.$on('$viewContentLoaded', function(){
        bioEp.init({
            cookieExp: 30,
            showOncePerSession: true,
            delay: 0,
            showPopupCallback: vm.showOfferWindow
        });
    });

    vm.showOfferWindow = function () {
        if (!vm.appConfig.options.config.banner_on_close.off) {
            $('#modalOffer').modal({
                show: true
            });
        }
    };

    $rootScope.$on( '$routeChangeSuccess', function( event, next, current )
    {
        BannersService.checkBanners();

        var newPath = $location.path();

        $metrika.counterIsLoaded().then( function (counter)
        {
            return counter.hit(newPath, {
                referer: path
            });
        });
        /* off gtag after 24.02.22
            if ( vm.appConfig.options.config && vm.appConfig.options.config.google_analytics_id != "" )
            {
                gtag('config', vm.appConfig.options.config.google_analytics_id, {'page_path': path});
                gtag('config', 'AW-1001962874');
            }
        */
        //fbq('track', 'PageView'); //off Facebook pixel after 24.02.22

        path = newPath;
    });

    vm.getKeynote = function () {
        return vm.appConfig.options.config.keynote;
    };

    $scope.$watch(
        function () {
            return vm.search.options.searchString;
        },
        function (newVal) {
            vm.search.search(newVal);
        }
    );

    vm.getFaviconPath = function () {
        if (vm.appConfig.options.config && vm.appConfig.options.config.favicon) {
            return vm.appConfig.options.config.favicon;
        } else {
            return '/favicon.ico';
        }
    };

    vm.getFaviconType = function () {
        if (vm.appConfig.options.config && vm.appConfig.options.config.favicon_type) {
            return vm.appConfig.options.config.favicon_type;
        } else {
            return '/image/x-icon';
        }
    };

    vm.needCartIcon = function () {
        return vm.appConfig.options.isInit !== false &&
            $location.$$path !== '/cart' &&
            $location.$$path !== '/bouquet-on-photo' &&
            $location.$$path !== '/collect' &&
            $location.$$path.indexOf('/success') &&
            $location.$$path !== '/maintenance';
    }
}

module.exports = AppController;